import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  removeTrailingNewlines = (trixEditor) => {
    if (trixEditor.element.innerHTML.match(/<br><\/div>$/)) {
      trixEditor.element.innerHTML = trixEditor.element.innerHTML.slice(0, -10) + "</div>"
      this.removeTrailingNewlines(trixEditor)
    }
  }

  removeTrailingWhitespace = (trixEditor) => {
    if (trixEditor.element.innerHTML.match(/&nbsp;<\/div>$/)) {
      trixEditor.element.innerHTML = trixEditor.element.innerHTML.slice(0, -12) + "</div>"
      this.removeTrailingWhitespace(trixEditor)
    } else if (trixEditor.element.innerHTML.match(/&nbsp; <\/div>$/)) {
      trixEditor.element.innerHTML = trixEditor.element.innerHTML.slice(0, -13) + "</div>"
      this.removeTrailingWhitespace(trixEditor)
    }
  }
}
