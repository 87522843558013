import { Controller } from "@hotwired/stimulus";
import { getUserMedia, getDisplayMedia, Space, SpaceEvent } from "@mux/spaces-web";
import { post } from "@rails/request.js";

export default class extends Controller {
    localParticipantEl = null;
    participantsEl = null;
    roomId = null;
    space = null;
    localParticipant = null;
    screenShare = null;
    videoDevices = [];
    audioDevices = [];
    states = {
        video: {
            local: "detached",
            remote: "unpublished",
        },
        audio: {
            local: "detached",
            remote: "unpublished",
        },
        screen: "unpublished",
    };
    tracks = {
        video: null,
        audio: null,
        screen: null,
    };

    static targets = ["audioDevices", "audioDeviceTemplate", "videoDevices", "videoDeviceTemplate", "videoContainer", "remoteVideoTemplate"];
    connect() {
        this.roomId = this.data.get("roomId");
        this.localParticipantEl = document.getElementById("local-video");
        this.participantsEl = document.getElementById("video-container");
        this.startLocalVideo();
    }

    getPreferredAudio = async () => {
        let name = localStorage.getItem("preferredAudio");
        let preferred = null;
        
        if (name) {
            let devices = await navigator.mediaDevices.enumerateDevices();
            for (let i = 0; i < devices.length; i++) {
                if (devices[i].kind === "audioinput" && devices[i].label === name) {
                    preferred = devices[i].deviceId;
                    break;
                }
            }
        }
        return preferred;
    };

    getPreferredVideo = async () => {
        let name = localStorage.getItem("preferredVideo");
        let preferred = null;
        if (name) {
            let devices = await navigator.mediaDevices.enumerateDevices();
            for (let i = 0; i < devices.length; i++) {
                if (devices[i].kind === "videoinput" && devices[i].label === name) {
                    preferred = devices[i].deviceId;
                    break;
                }
            }
        }
        return preferred;
    };

    getAudioTracks = async (deviceId = null) => {
        let tracks = [];
        try {
            if (deviceId) {
                tracks = await getUserMedia({
                    video: false,
                    audio: { deviceId: { exact: deviceId } },
                });
            } else {
                tracks = await getUserMedia({
                    video: false,
                    audio: true,
                });
            }
        } catch (err) {
            // Log the error
            console.log("Media device error: ", err);
        }
        return tracks;
    };

    getVideoTracks = async (deviceId = null) => {
        let tracks = [];
        try {
            if (deviceId) {
                tracks = await getUserMedia({
                    video: { deviceId: { exact: deviceId } },
                    audio: false,
                });
            } else {
                tracks = await getUserMedia({
                    video: true,
                    audio: false,
                });
            }
        } catch (err) {
            // Log the error
            console.log("Media device error: ", err);
        }
        return tracks;
    };

    getAllTracks = async (preferredAudioId, preferredVideoId) => {
        let tracks = [];
        let a_tracks = [];
        let v_tracks = [];

        try {
            //First, try to get the preferred audio track
            if (preferredAudioId) {
                try {
                    a_tracks = await getUserMedia({
                        video: false,
                        audio: { deviceId: { exact: preferredAudioId } },
                    });
                    tracks.push(a_tracks[0]);
                } catch (audioError) {
                    try {
                        a_tracks = await getUserMedia({
                            video: false,
                            audio: true,
                        });
                        tracks.push(a_tracks[0]);
                    } catch (audioError) {
                        console.log("Error finding audio input");
                    }
                }
            } else {
                try {
                    a_tracks = await getUserMedia({
                        video: false,
                        audio: true,
                    });
                    tracks.push(a_tracks[0]);
                } catch (audioError) {
                    console.log("Error finding audio input");
                }
            }

            if (preferredVideoId) {
                try {
                    v_tracks = await getUserMedia({
                        video: { deviceId: { exact: preferredVideoId } },
                        audio: false,
                    });
                    tracks.push(v_tracks[0]);
                } catch (videoError) {
                    try {
                        v_tracks = await getUserMedia({
                            video: true,
                            audio: false,
                        });
                        tracks.push(v_tracks[0]);
                    } catch (videoError) {
                        console.log("Error finding video input");
                    }
                }
            } else {
                try {
                    v_tracks = await getUserMedia({
                        video: true,
                        audio: false,
                    });
                    tracks.push(v_tracks[0]);
                } catch (videoError) {
                    console.log("Error finding video input");
                }
            }
        } catch (err) {
            // Log the error
            console.log("Media device error: ", err);
        }
        return tracks;
    };

    startLocalVideo = async () => {
        const response = await post(`/rooms/${this.roomId}/join`, { id: this.roomId });
        // Parse the JSON from the response
        const data = await response.json;
        const jwt = data.jwt;

        // Get camera and microphone tracks
        let localTracks = null;
        let remoteTracks = null;

        let preferredAudioId = await this.getPreferredAudio();
        let preferredVideoId = await this.getPreferredVideo();

        //Locally, we only want video
        localTracks = await this.getVideoTracks(preferredVideoId);

        //Remotely, we want both audio and video
        remoteTracks = await this.getAllTracks(preferredAudioId, preferredVideoId);

        if (localTracks) {
            // Render local media tracks
            localTracks.forEach((track) => {
                if (track.track.kind === "video") {
                    this.states.video.local = "attached";
                } else if (track.track.kind === "audio") {
                    this.states.audio.local = "attached";
                }
                track.attach(this.localParticipantEl);
            });
        }

        // Instantiate our space
        this.space = new Space(jwt);

        // Setup event listeners for other people joining and leaving
        this.space.on(SpaceEvent.ParticipantTrackSubscribed, this.addTrack);
        this.space.on(SpaceEvent.ParticipantTrackUnsubscribed, this.removeTrack);
        this.space.on(SpaceEvent.ParticipantLeft, this.removeParticipant);

        // Join the Space
        this.localParticipant = await this.space.join();

        if (remoteTracks) {
            remoteTracks.forEach((track) => {
                if (track.track.kind === "video") {
                    this.tracks.video = track;
                } else if (track.track.kind === "audio") {
                    this.tracks.audio = track;
                }
            });
            // Publish local media tracks
            await this.localParticipant.publishTracks(remoteTracks);
            this.states.video.remote = "published";
            this.states.audio.remote = "published";
        }
    };

    shareScreen = async () => {
        //Query User for screen share source
        const mediaStream = await getDisplayMedia();

        if (mediaStream) {
            this.tracks.screen = mediaStream[0];
            this.states.screen = "published";
            // Publish screen share track
            let menuItem = document.getElementById("stop-start-screen-share");
            menuItem.setAttribute("data-action", "click->room#stopScreenShare");
            menuItem.setAttribute("data-room-manual-param", true);
            menuItem.innerHTML = "Stop Sharing Screen";
            await this.localParticipant.publishTracks(mediaStream);

            this.tracks.screen.attach(document.getElementById("local-screenshare-container").querySelector("video"));
            document.getElementById("local-screenshare-container").classList.remove("hidden");

            this.tracks.screen.track.addEventListener("ended", (event) => {
                this.stopScreenShare(event);
            });
        }
    };

    stopScreenShare = async (event) => {
        if (this.states.screen === "published" && this.tracks.screen) {
            this.tracks.screen.stop();

            //Our UI button was pressed.
            if (event.params && event.params.manual) {
                await this.localParticipant.unpublishTracks([this.tracks.screen]);
            }

            let menuItem = document.getElementById("stop-start-screen-share");
            menuItem.setAttribute("data-action", "click->room#shareScreen");
            menuItem.innerHTML = "Share screen";
        }
        this.states.screen = "unpublished";
        this.tracks.screen = null;
        document.getElementById("local-screenshare-container").classList.add("hidden");
    };

    newTrackAdded = async (participant, track) => {
        console.log(participant);
        console.log(track);
    };

    // Creates or updates a <video> element in the page when a participant's track becomes available
    addTrack = async (participant, track) => {
        const screenshare = track.source === "screenshare";
        let id = screenshare ? "screenshare-" + participant.connectionId : participant.connectionId;
        let remoteVideo = document.getElementById(id);
        if (!remoteVideo) {
            let clone = this.remoteVideoTemplateTarget.content.cloneNode(true);
            let div = clone.querySelector("div");
            let video = clone.querySelector("video");
            video.setAttribute("id", id);
            div.setAttribute("id", "container-" + id);

            this.videoContainerTarget.appendChild(div);
            remoteVideo = video;
        }

        this.enableVideoDisplay("container-" + id);

        track.attach(remoteVideo);
    };

    async setAudioDevice(event) {
        let deviceId = event.params.deviceId;

        // Get microphone track
        let tracks = await this.getAudioTracks(deviceId);
        this.tracks.audio = tracks[0];

        //Store preferred Audio to persist it when it's available
        localStorage.setItem("preferredAudio", this.tracks.audio.name);

        if (this.states.audio.remote == "published") {
            // Update tracks
            await this.localParticipant.updateTracks(tracks);
        } else {
            // Publish tracks
            await this.localParticipant.publishTracks(tracks);
            this.states.audio.remote = "published";
        }
    }

    setVideoDevice = async (event) => {
        let deviceId = event.params.deviceId;

        // Get camera track
        let tracks = await this.getVideoTracks(deviceId);
        this.tracks.video = tracks[0];

        if (this.states.screen === "published" && this.tracks.screen) {
            tracks.push(this.tracks.screen);
        }

        // Render local media tracks
        this.states.video.local = "attached";
        tracks[0].attach(this.localParticipantEl);

        //Store preferred Video to persist it when it's available
        localStorage.setItem("preferredVideo", this.tracks.video.name);

        let publishedTracks = await this.localParticipant.getTracks();
        console.log("published tracks before: ", publishedTracks);
        if (this.states.video.remote === "published") {
            // Update local media tracks
            await this.localParticipant.updateTracks(tracks);
        } else {
            // Publish local media tracks
            await this.localParticipant.publishTracks(tracks);
            this.states.video.remote = "published";
        }

        //Toggle Local Video
        this.enableVideoDisplay("local-video-container");

        publishedTracks = await this.localParticipant.getTracks();
        console.log("published tracks after: ", publishedTracks);
    };

    mute = async () => {
        if (this.tracks.audio && this.states.audio.remote === "published") {
            await this.localParticipant.unpublishTracks([this.tracks.audio]);
            this.tracks.audio.stop();
            this.tracks.audio.detach(this.localParticipantEl);
            this.states.audio.remote = "unpublished";
            this.tracks.audio = null;
        }
    };

    turnOffCamera = async () => {
        if (this.tracks.video && (this.states.video.remote === "published" || this.states.video.local === "attached")) {
            await this.localParticipant.unpublishTracks([this.tracks.video]);
            this.tracks.video.detach(this.localParticipantEl);
            this.tracks.video.stop();
            this.localParticipantEl.src = "";
            this.localParticipantEl.srcObject = null;
            this.localParticipantEl.load();
            this.states.video.remote = "unpublished";
            this.states.video.local = "detached";
            this.tracks.video = null;

            this.disableVideoDisplay("local-video-container");
        }
    };

    leaveRoom = async () => {
        await this.localParticipant.unpublishAllTracks();
        window.location.href = "/rooms";
    }

    mirrorMode = async () => {
        if (this.tracks.video && this.states.video.remote === "published") {
            //Unpublish the track
            await this.localParticipant.unpublishTracks([this.tracks.video], { stop: false });
            this.states.video.remote = "unpublished";
        }

        if (!this.tracks.video) {
            let tracks = await this.getVideoTracks();
            this.tracks.video = tracks[0];
        }

        if (this.states.video.local === "detached") {
            this.tracks.video.attach(this.localParticipantEl);
            this.states.video.local = "attached";

            //Toggle Local Video
            this.enableVideoDisplay("local-video-container");
        }
    };

    async listAudioDevices() {
        // Clear the list
        this.audioDevicesTarget.innerHTML = "";

        let devices = await navigator.mediaDevices.enumerateDevices();
        this.audioDevices = [];
        if (this.states.audio.remote === "published" && this.tracks.audio) {
            this.audioDevices.push(this.tracks.audio);
            // Add a new item to the list
            let option = this.audioDeviceTemplateTarget.content.cloneNode(true);
            let anchor = option.querySelector("a");
            anchor.setAttribute("data-action", "click->room#mute");
            anchor.innerHTML = "Mute Audio";
            this.audioDevicesTarget.appendChild(option);
        }
        for (let i = 0; i < devices.length; i++) {
            if (devices[i].kind === "audioinput") {
                this.audioDevices.push(devices[i]);
                // Add a new item to the list
                let option = this.audioDeviceTemplateTarget.content.cloneNode(true);
                let anchor = option.querySelector("a");
                anchor.setAttribute("data-action", "click->room#setAudioDevice");
                anchor.setAttribute("data-room-device-id-param", devices[i].deviceId);
                anchor.innerHTML = devices[i].label;
                this.audioDevicesTarget.appendChild(option);
            }
        }
    }

    listVideoDevices = async () => {
        // Clear the list
        this.videoDevicesTarget.innerHTML = "";
        let devices = await navigator.mediaDevices.enumerateDevices();
        this.videoDevices = [];
        if (this.tracks.video && (this.states.video.remote === "published" || this.states.video.local === "attached")) {
            // Add a new item to the list
            let option = this.videoDeviceTemplateTarget.content.cloneNode(true);
            let anchor = option.querySelector("a");
            anchor.setAttribute("data-action", "click->room#turnOffCamera");
            anchor.innerHTML = "Turn Off Camera";
            this.videoDevicesTarget.appendChild(option);
        }

        //Add Mirror Mode option
        //Unless the state is remote =="unpublished" && local=="attached", show mirror mode as an option
        if (!(this.states.video.remote === "unpublished" && this.states.video.local === "attached")) {
            // Add a new item to the list
            let option = this.videoDeviceTemplateTarget.content.cloneNode(true);
            let anchor = option.querySelector("a");
            anchor.setAttribute("data-action", "click->room#mirrorMode");
            anchor.innerHTML = "Mirror";
            this.videoDevicesTarget.appendChild(option);
        }

        for (let i = 0; i < devices.length; i++) {
            if (devices[i].kind === "videoinput") {
                this.videoDevices.push(devices[i]);
                // Add a new item to the list
                let option = this.videoDeviceTemplateTarget.content.cloneNode(true);
                let anchor = option.querySelector("a");
                anchor.setAttribute("data-action", "click->room#setVideoDevice");
                anchor.setAttribute("data-room-device-id-param", devices[i].deviceId);
                anchor.innerHTML = devices[i].label;
                this.videoDevicesTarget.appendChild(option);
            }
        }
        console.log(this.videoDevices);
    };

    // Removes a participant's track when it is no longer available
    removeTrack = async (participant, track) => {
        const screenshare = track.source === "screenshare";
        let id = screenshare ? "screenshare-" + participant.connectionId : participant.connectionId;
        const remoteVideo = document.getElementById(id);
        track.detach(remoteVideo);

        this.disableVideoDisplay("container-" + id);

        if (screenshare) {
            this.participantsEl.removeChild(document.getElementById("container-" + id));
        }
    };

    // Removes the appropriate <video> element from the page when a participant leaves
    removeParticipant = async (participant) => {
        this.participantsEl.removeChild(document.getElementById("container-" + participant.connectionId));
    };

    enableVideoDisplay(container_id) {
        let phEl = document.getElementById(container_id).querySelector("div");
        let vidEl = document.getElementById(container_id).querySelector("video");
        vidEl.classList.remove("hidden");
        phEl.classList.add("hidden");
    }

    disableVideoDisplay(container_id) {
        let phEl = document.getElementById(container_id).querySelector("div");
        let vidEl = document.getElementById(container_id).querySelector("video");
        vidEl.classList.add("hidden");
        phEl.classList.remove("hidden");
    }
}
