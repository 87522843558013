import { Controller } from "@hotwired/stimulus"
import consumer from "../channels/consumer"

// Connects to data-controller="user-navbar"
export default class extends Controller {
  static targets = ["badge", "list", "placeholder", "notification"];
  static values = {
    accountId: String, // Current account ID
    accountUnread: Number, // Unread count for the current account
    totalUnread: Number, // Unread count across all the user's accounts
  };
  connect() {
    this.subscription = consumer.subscriptions.create({ channel: "NotificationChannel" }, {
      connected: this._connected.bind(this),
      disconnected: this._disconnected.bind(this),
      received: this._received.bind(this)
    })    

    if (this.hasUnread()) {
      console.log("got a new notification");
      //this.showUnreadBadge()
    }
  }
  _connected() {    
  }

  _disconnected() {
  }

  hasUnread() {
    return !!this.accountUnreadValue
  }

  showUnreadBadge() {
    if (this.hasBadgeTarget == false) { return }
    this.badgeTarget.classList.remove("hidden")
  }

  _received(data) {    
    this.totalUnreadValue += 1

    // Ignore if user is signed in to a different account
    if (data.account_id && data.account_id != this.accountIdValue) {
      return
    }
    
    if (data.account_id && data.kind == "form submission" && data.account_id == this.accountIdValue) {
      this.accountUnreadValue += 1
      this.showUnreadBadge()
    }
    /*
    

    // Regular notifications get added to the navbar
    if (data.html) {
      this.listTarget.insertAdjacentHTML('afterbegin', data.html)
      this.showUnreadBadge()
    }

    // Native notifications trigger a browser notification
    if (data.browser) {
      this.checkPermissionAndNotify(data.browser)
    }*/
  }
}
