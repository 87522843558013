import { Controller } from "@hotwired/stimulus";
import { post } from '@rails/request.js';

export default class extends Controller {
    static targets = [];
    shopping_cart = null;
    connect() {}

    async show(event) {
        console.log(event.target.checked);
        if(event.target.checked){
            post('/shopping_carts/get_payment_methods', { body: {}, responseKind: 'turbo-stream' });
        }
    }

    hideCartIfEmpty(event) {
        this.shopping_cart = document.querySelector("#shoppingCartModal");
        this.shopping_cart.checked = false;
    }
}
