import { Controller } from "@hotwired/stimulus";
import Uppy from "@uppy/core";
// import ProgressBar from "@uppy/progress-bar";
import Dashboard from "@uppy/dashboard";
import ActiveStorageUpload from "@excid3/uppy-activestorage-upload";
import ImageEditor from "@uppy/image-editor";
import Webcam from "@uppy/webcam";
import Rails from "@rails/ujs";

// import ScreenCapture from "@uppy/screen-capture";

export default class extends Controller {
  static targets = []

  connect() {
    this.direct_upload_url = document.querySelector("meta[name='direct-upload-url']").getAttribute("content")
  }

  async openImageModal (e) {
    e.preventDefault();
    const modal = document.querySelector('#imageUploadModal');
    modal.checked = true;

    this.square = document.querySelector("#uppy-square-trigger");
    this.setupUppy(this.square);

    this.portrait = document.querySelector("#uppy-portrait-trigger");
    this.setupUppy(this.portrait);

    this.landscape = document.querySelector("#uppy-landscape-trigger");
    this.setupUppy(this.landscape);

    console.log("works")

    // this.gif = document.querySelector("#uppy-gif-trigger");
    // this.setupUppy(this.gif);
  }

  async openGifModal (e) {
    e.preventDefault();
    const modal = document.querySelector('#gifUploadModal');
    modal.checked = true;

    this.gif = document.querySelector("#uppy-gif-trigger");
    this.setupUppy(this.gif);
  }

  setupUppy(element) {    
    // If you want to submit form with ajax on complete rather than show preview
    // let form = element.closest('form')
    let field_name = element.dataset.uppyField
    let type = element.dataset.uppyType

    let uppy = Uppy({
      autoProceed: false,
      allowMultipleUploads: false,
      logger: Uppy.debugLogger,
      restrictions: {
        maxNumberOfFiles: 1,
        allowedFileTypes: ['image/*'],
      },
    }).use(ActiveStorageUpload, {
      directUploadUrl: this.direct_upload_url
    })
    
    this.trigger = element.querySelector('[data-behavior="uppy-trigger"]')
    this.trigger.addEventListener("click", (event) => event.preventDefault())
    uppy.use(Dashboard, {
      trigger: this.trigger,
      closeAfterFinish: true,
      proudlyDisplayPoweredByUppy: false,
      theme: 'dark',
      autoOpenFileEditor: type === "gif" ? false : true,
      closeModalOnClickOutside: true
    })
    
    switch (type) {
      case "square":
        uppy = this.uppySquare(uppy)
        break;
      case "landscape":
        uppy = this.uppyLandscape(uppy)
        break;
      case "portrait":
        uppy = this.uppyPortrait(uppy)
        break;
      case "gif":
        //Use uppy without Image Editor
        break;
      default:
        break;
    }
  
    if (uppy !== "") {
      uppy.on('complete', (result) => {

        console.log("Set breakpoint here")

        element.querySelectorAll('[data-pending-upload]').forEach(element => element.parentNode.removeChild(element))
    
        result.successful.forEach(file => {
          this.appendUploadedFile(element, file, field_name);
          this.appendAspectRatio(element, type);
          let formElement = element.closest('form');
          if (formElement) {
            Rails.fire(formElement, "submit");
          }

          //Once the submit button is pressed, hide the action menu and the modal
          const modal = document.querySelector('#imageUploadModal');
          modal.checked = false;

          const layoutControllerElement = document.querySelector('[data-controller~="layout"]');
          const layoutController = this.application.getControllerForElementAndIdentifier(layoutControllerElement, "layout");
          layoutController.toggleActionMenu();
        });
    
        // uppy.reset()
      })
    }    
  }

  uppySquare(uppy) {
    uppy.use(ImageEditor, { 
      target: Dashboard,
      quality: 0.8,
      cropperOptions: {
        viewMode: 0,
        background: false,
        autoCropArea: 0.8,
        responsive: true,
        aspectRatio: 1/1,
      },
      actions: {
        revert: false,
        rotate: false,
        granularRotate: true,
        flip: false,
        zoomIn: true,
        zoomOut: true,
        cropSquare: false,
        cropWidescreen: false,
        cropWidescreenVertical: false,
      }
    })

    return uppy;
  }

  uppyLandscape(uppy) {
    uppy.use(ImageEditor, { 
      target: Dashboard,
      cropperOptions: {
        viewMode: 0,
        background: false,
        autoCropArea: 1,
        responsive: true,
        aspectRatio: 16/9,
      },
      actions: {
        revert: false,
        rotate: false,
        granularRotate: true,
        flip: false,
        zoomIn: true,
        zoomOut: true,
        cropSquare: false,
        cropWidescreen: true,
        cropWidescreenVertical: false,
      }
    })
    return uppy;
  }

  uppyPortrait(uppy) {
    uppy.use(ImageEditor, { 
      target: Dashboard,
      cropperOptions: {
        viewMode: 0,
        background: false,
        autoCropArea: 1,
        responsive: true,
        aspectRatio: 9/16,
      },
      actions: {
        revert: false,
        rotate: false,
        granularRotate: true,
        flip: false,
        zoomIn: true,
        zoomOut: true,
        cropSquare: false,
        cropWidescreen: false,
        cropWidescreenVertical: true,
      }
    })
    return uppy;
  }
  
  appendUploadedFile(element, file, field_name) {
    const hiddenField = document.createElement('input')

    hiddenField.setAttribute('type', 'hidden')
    hiddenField.setAttribute('name', field_name)
    hiddenField.setAttribute('data-pending-upload', true)
    hiddenField.setAttribute('value', file.response.signed_id)

    element.appendChild(hiddenField)
  }

  appendAspectRatio(element, type) {
    const hiddenField = document.createElement('input')

    hiddenField.setAttribute('type', 'hidden')
    hiddenField.setAttribute('name', 'medium[aspect_ratio]')
    hiddenField.setAttribute('value', type)

    element.appendChild(hiddenField)
  }

  setPreview(element, file) {
    let preview = element.querySelector('[data-behavior="uppy-preview"]')
    if (preview) {
      let src = (file.preview) ? file.preview : "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSpj0DBTVsaja01_xWh37bcutvpd7rh7zEd528HD0d_l6A73osY"
      preview.src = src
    }
  }
}