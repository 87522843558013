import { Controller } from "@hotwired/stimulus"
import Sortable from 'sortablejs';
import { post } from '@rails/request.js';

// Connects to data-controller="task-board"
export default class extends Controller {

  connect() {        
    document.querySelectorAll('.task-list-container').forEach(function(el) {
      // console.log(el)
      Sortable.create(el, {
        direction: "vertical",
        group: "tasks",
        onEnd: function (evt) {
          console.log(evt);
          var new_list_id = evt.to.dataset.listId;
          var old_list_id = evt.item.dataset.listId;
          var task_id = evt.item.dataset.taskId;
          var new_position = evt.newIndex;
          var starting_position = evt.oldIndex;

          if(new_list_id == old_list_id){
            console.log("Moving task " + task_id + " to position " + new_position + " from " + starting_position);
          } else {
            console.log("Moving task " + task_id + " from list " + old_list_id + " to list " + new_list_id + " at position " + new_position);
          }

          //Now, make the call to the backend to make the move
          //but only if the task was actually moved
          if(new_list_id != old_list_id || new_position != starting_position){
            post('/tasks/move_task', { 
              body: {
                task_id: task_id,
                new_list_id: new_list_id,
                new_position: new_position
              } 
            });
          }
        }
      });
    });
  }
}