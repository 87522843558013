import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [  ]
  element = null;
  subject = null;

  useStockEmail(event) {
    if (document.getElementById("broadcast-trix")) {
      this.trix = document.getElementById("broadcast-trix");
            
      if (document.getElementById("broadcast_subject")) {
        this.subject = document.getElementById("broadcast_subject");
        // Update the subject field
        this.subject.value = event.params.subject;
      }
    } else {
      this.trix = document.querySelector("#trix-message-container trix-editor");
    }   

    // Clear the contents of the trix body
    this.trix.editor.loadHTML(""); 
    
    // Update the trix body
    this.trix.editor.insertHTML(event.params.email);
    
    //Close the modal
    this.modalCheckbox = document.getElementById("stockEmailChooserModal");
    this.modalCheckbox.checked = false;
  }
  
  insertTextSnippet(event) {
    this.trix = document.getElementById("broadcast-trix");
    this.modalCheckbox = document.getElementById("textSnippetChooserModal");

    this.trix.editor.insertHTML(event.params.snippet);
    
    //Close the modal
    this.modalCheckbox.checked = false;
  }

  setTextSnippetToSave(event) {
      this.element = document.querySelector("trix-editor");
      this.newContent =  document.getElementById("newSnippetContent");
      this.modalCheckbox = document.getElementById("textSnippetSaverModal");
      if (this.modalCheckbox.checked) {
          var textString = this.element.editor.getDocument().toString();
          //For some reason, it always appends a \n.  Strip it
          textString = textString.slice(0, textString.length - 1);

          this.newContent.value = textString;
      }
  }

  insertMergeField (event){
    if(event.params.subject){
      const subject = document.getElementById("broadcast_subject");
      const value = subject.value;
      const position = subject.selectionStart;

      //Grab the part of the subject preceding the caret
      const f = value.slice(0, position);

      //Grab whatever remains after the caret (can be empty)
      const s = value.slice(position);

      //Splice the merge field in wherever the caret is
      subject.value = f + event.params.field + s;

      //This puts the focus on the subject input,
      // so the user can click a merge field and then keep typing
      subject.focus();
    } else {
      this.trix = document.getElementById("broadcast-trix");
      const message_editor = this.trix.editor;

      //Insert merge field wherever the caret is
      message_editor.insertString(event.params.field);     
    }
  }
}
