import Rails from "@rails/ujs";
import TrixController from "./trix_controller";

const ERROR_BLANK = "Can't be blank"

const validatePresence = (input) => {
  if (input.hasAttribute('required')) {
    if (input.value.trim() === '') {
      input.setCustomValidity(ERROR_BLANK)
      return false
    } else {
      if (input.validity.customError) input.setCustomValidity('')
      return true
    }
  } else {
    return true
  }
}

export default class extends TrixController {
  static targets = [ "newMessage", "messageForm" ]

  connect() {
    this.element.querySelectorAll('input[required]').forEach((input) => {
      input.addEventListener('input', this.validateInput)
    })
  }

  disconnect() {
    this.element.querySelectorAll('input[required]').forEach((input) => {
      input.removeEventListener('input', this.validateInput)
    })
  }

  validateInput() {
    validatePresence(this) // && validateSomethingElse()
  }

  resetForm() {
    this.element.reset()
  }

  sendWithEnter(event) {
    // Allow users to submit message with enter/return and function normally if SHIFT key is pressed
    if (event.keyCode == 13 && !(event.shiftKey)) {
      event.preventDefault()

      // Make sure trix editor field isn't empty
      if (this.newMessageTarget.innerHTML != "") {
        // Use Trix element.editor to remove trailing whitespace and new lines
        this.removeTrailingNewlines(this.newMessageTarget.editor)
        this.removeTrailingWhitespace(this.newMessageTarget.editor)
        // Set the form message to the new stripped value before submit
        this.newMessageTarget.editor.loadHTML(this.newMessageTarget.innerHTML)
        Rails.fire(this.messageFormTarget, 'submit');
      }
    }
  }
}