/* eslint no-console:0 */

// Rails functionality
import Rails from "@rails/ujs"
import { Turbo } from "@hotwired/turbo-rails"

// Make accessible for Electron and Mobile adapters
window.Rails = Rails
window.Turbo = Turbo

require("@rails/activestorage").start()
import "@rails/actiontext"

// ActionCable Channels
import "./channels"

// Stimulus controllers
import "./controllers"

//AlpineJS
import Alpine from 'alpinejs'
import mask from '@alpinejs/mask'

Alpine.plugin(mask);
Alpine.start();

// Jumpstart Pro & other Functionality
import "./src/**/*"
require("local-time").start()

// include the video.js kit JavaScript and CSS
import videojs from '@mux/videojs-kit';
//import '@mux/mux-uploader';

// Start Rails UJS
Rails.start()
