import { Controller } from "@hotwired/stimulus";
import '@mux/mux-uploader';

import { post, patch } from '@rails/request.js';

export default class extends Controller {
    
    static targets = [];
    connect() {
        const muxUploader = document.querySelector('mux-uploader');
        //If Uploader is defined, we know the element has been added to the DOM and we can add an event listener
        if(muxUploader){
            muxUploader.addEventListener('success', async () => {
                var nameElement = document.getElementById('mux-video-name');
                var idElement = document.getElementById('mux-media-id');
                await post('/media/set_mux_video_name', {body: {name: nameElement.value, id: idElement.value}});
         

                //Hide the video upload
                const modal = document.querySelector('#videoUploadModal');
                modal.checked = false;

                //Once the upload is complete, toggle the action menu
                const layoutControllerElement = document.querySelector('[data-controller~="layout"]');
                const layoutController = this.application.getControllerForElementAndIdentifier(layoutControllerElement, "layout");
                layoutController.toggleActionMenu();
            });
        }
    }

    async openVideoModal (e) {
        e.preventDefault();
        const modal = document.querySelector('#videoUploadModal');
        modal.checked = true;
        post('/media/get_mux_upload_link', { body: {}, responseKind: 'turbo-stream' });
    }
}
