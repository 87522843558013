import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    unseenId: Number
  }

  connect() {
    // Add border for showing where unread messages begin
    if (this.unseenIdValue > 0) {
      document.getElementById(this.unseenIdValue).classList.add("last-seen")
    }

    this.scrollToBottom()
  }

  scrollToBottom() {
    // Consider adding logic to scroll to first unread rather than bottom
    this.element.scrollTo(0,this.element.scrollHeight)
  }
}