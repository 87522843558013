import { Controller } from '@hotwired/stimulus';
import { patch } from '@rails/request.js';

export default class extends Controller {
  static targets = []
  
  connect() {
    console.log("media chooser")
  }

  chooseForm (e) {
    patch('/build/pages/update_ingredient', { body: {page_block_ingredient: {source_id: e.params.mediumId, id: e.params.ingredientId}}, responseKind: 'turbo-stream' }); 
    document.querySelector("#ingredientEditorModal").checked = false;
  }

  chooseImage (e) {
    patch('/build/pages/update_ingredient', { body: {page_block_ingredient: {source_id: e.params.mediumId, id: e.params.ingredientId}}, responseKind: 'turbo-stream' }); 
    document.querySelector("#ingredientEditorModal").checked = false;
  }

  chooseLink (e) {
    patch('/build/pages/update_ingredient', { body: {page_block_ingredient: {source_id: e.params.mediumId, id: e.params.ingredientId}}, responseKind: 'turbo-stream' }); 
    document.querySelector("#ingredientEditorModal").checked = false;
  }

  chooseLinks (e) {
    console.log(e.params)
    patch('/build/pages/update_ingredient', { body: {page_block_ingredient: {id: e.params.ingredientId, value: e.params.selectedLinks}}, responseKind: 'turbo-stream' }); 
    document.querySelector("#ingredientEditorModal").checked = false;
  }
  
  chooseVideo (e) {
    patch('/build/pages/update_ingredient', { body: {page_block_ingredient: {source_id: e.params.mediumId, id: e.params.ingredientId}}, responseKind: 'turbo-stream' });
    document.querySelector("#ingredientEditorModal").checked = false;
  }

  closeKitchen () {
    document.querySelector("#ingredientEditorModal").checked = false;
  }
}