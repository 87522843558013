import { Controller } from "@hotwired/stimulus";
import { enter, leave } from 'el-transition';
import { post } from '@rails/request.js';

// Connects to data-controller="dms"
export default class extends Controller {

  connect() {
  }

  openConversationById (event ) {
    let conversationId = event.params.conversationId;
    
    post('/conversations/get_conversation', { body: {id: conversationId}, responseKind: 'turbo-stream' });
  }
  
  createThread () {
    post('/dms/create_thread', { body: {}, responseKind: 'turbo-stream' });
  }

  openThreadById(event){
    let threadId = event.params.threadId;
    post('/dms/get_thread', { body: {id: threadId}, responseKind: 'turbo-stream' });
  }

  clearNewMessageInput (){
    let inputElement = document.querySelector("#chat-text");
    inputElement.value = "";
  }

  deleteThread(event){
    event.stopImmediatePropagation();
    
    let threadId = event.params.threadId;
    let threadElement = document.querySelector("#message-thread-" + threadId);
    let current = !!threadElement;
    post('/dms/delete_thread', { body: {id: threadId, current: current}, responseKind: 'turbo-stream' });
  }

  scrollToBottom() {
    // Consider adding logic to scroll to first unread rather than bottom
    this.chatWindowTarget.scrollIntoView(false);
  }
}