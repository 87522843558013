import { Controller } from "@hotwired/stimulus"
import { enter, leave } from 'el-transition';

// Connects to data-controller="dom"
export default class extends Controller {
  connect() {
  }

  // Empty the contents of an html element
  emptyElement(event) {
    var elementId = event.currentTarget.dataset.elementid
    var elementToEmpty = document.getElementById(elementId)
    elementToEmpty.innerHTML = ''
  }

  // Close modal 
  closeModal(event) {
    var elementId = event.currentTarget.dataset.elementid
    var checkboxToUncheck = document.getElementById(elementId)
    // use the event target to grab the modal id and uncheck the checkbox
    checkboxToUncheck.checked = false
  }

  emptyTurboFrame(event) {
    var elementId = event.currentTarget.dataset.id
    var elementToEmpty = document.getElementById(elementId)
    elementToEmpty.innerHTML = ''
  }

  toggleCheckbox(event) {
    // if(event.params.tfId){
    //   document.querySelector('#'+tfId).innerHTML = `<progress class="progress w-56"></progress>`;
    // }
    var checkboxId = event.currentTarget.dataset.checkbox
    var checkbox = document.getElementById(checkboxId)
    if(event.currentTarget.dataset.clear){
      console.log("turbo frame clear!!!")

      var turboFrame = document.getElementById(event.currentTarget.dataset.clear);
      var asides = turboFrame.querySelectorAll("aside");

      if (asides.length) asides[0].innerHTML = "";
    }
    checkbox.checked = !checkbox.checked
  }

  setInputValue(event) {
    var inputId = event.srcElement.dataset.inputid    
    var inputField = document.getElementById(inputId)
    inputField.value = event.srcElement.dataset.fieldvalue
  }
}
