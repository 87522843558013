import { Controller } from '@hotwired/stimulus';
import papa from 'papaparse';
import { post } from '@rails/request.js';

export default class extends Controller {
  static targets = [ 'input', 'preview', 'results', 'count', 'loader', 'filename' ]
  contact_array = []
  
  connect() {
  }

  preview() {
    let resultsTable = this.resultsTarget    
    let filename = this.filenameTarget    
    if (this.inputTarget.files && this.inputTarget.files[0]) {      
      let csvFile = this.inputTarget.files[0]
      filename.innerHTML = csvFile.name
      if (csvFile.type == "text/csv") {
        this.previewTarget.classList.toggle('hidden')
        var count = 0;
        papa.parse(csvFile, {
          header: true,
          preview: 3,
          step: function(result) {
            var newRow = resultsTable.insertRow(),
              fnameCell = newRow.insertCell(0),
              lnameCell = newRow.insertCell(1),
              emailCell = newRow.insertCell(2),
              phoneCell = newRow.insertCell(3),
              categoryCell = newRow.insertCell(4),
              tagsCell = newRow.insertCell(5);
            
            fnameCell.innerHTML = result.data.first_name;
            lnameCell.innerHTML = result.data.last_name;
            emailCell.innerHTML = result.data.email;
            phoneCell.innerHTML = result.data.phone_number;
            categoryCell.innerHTML = result.data.category;
            tagsCell.innerHTML = result.data.tags ? result.data.tags.replace(/;/g, ",") : "";
          
            count += 1
          }
        })        
      } else {
        console.log("not a csv")
      }
    }    
  }

  parse(event) {
    event.currentTarget.classList.add('hidden')
    let preview = this.previewTarget
    let counter = this.countTarget   
    let loader = this.loaderTarget
    let resultsTable = this.resultsTarget
    let csvFile = this.inputTarget.files[0]
    var count = 0;
    this.contact_array = []
    let addContactsBtn = document.getElementById('addContactsBtn')
  
    loader.classList.toggle('hidden')
    for (var i = 0; i < 3; i++) {
      resultsTable.deleteRow(-1);
    }
    
    papa.parse(csvFile, {
      header: true,
      step: (result) => {
        var newRow = resultsTable.insertRow(),
          fnameCell = newRow.insertCell(0),
          lnameCell = newRow.insertCell(1),
          emailCell = newRow.insertCell(2),
          phoneCell = newRow.insertCell(3),
          categoryCell = newRow.insertCell(4),
          tagsCell = newRow.insertCell(5);
        
        fnameCell.innerHTML = result.data.first_name;
        lnameCell.innerHTML = result.data.last_name;
        emailCell.innerHTML = result.data.email;
        phoneCell.innerHTML = result.data.phone_number;
        categoryCell.innerHTML = result.data.category;
        tagsCell.innerHTML = result.data.tags ? result.data.tags.replace(/;/g, ",") : "";
      
        count += 1
        this.contact_array.push(result.data)
      },
      complete: (results, file) => {        
        counter.closest('p').classList.toggle('hidden')
        counter.innerHTML = count
        loader.classList.toggle('hidden')
        addContactsBtn.classList.toggle('hidden')
      }
    })   
  }

  async upload(event) {
    for (let i = 0; i < this.contact_array.length; i++) {      
      var percentage = Math.round((i / (this.contact_array.length - 1)) * 100)
      var first_name = this.contact_array[i].first_name
      var last_name = this.contact_array[i].last_name
      var email = this.contact_array[i].email
      var phone_number = this.contact_array[i].phone_number
      var category = this.contact_array[i].category
      var tag_list = this.contact_array[i].tags ? this.contact_array[i].tags.replace(/;/g, ",") : ""    

      await post('/contacts/upload_individual_contact', { body: { contact: { first_name, last_name, email, phone_number, category, tag_list }, percentage: percentage }, responseKind: 'turbo-stream' });
    }
  }
}