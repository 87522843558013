import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "slideInContainer", "slideInTrigger", "step", "steps", "progressBar", "nextButton", "prevButton", "cancelButton", "finishButton" ]
  static values = { currentStep: Number, lastStep: Number }

  initialize() {    
    this.currentStep = 1
  }

  connect() { 
    this.lastStep = this.stepsTarget.dataset.totalsteps
    const urlParams = new URLSearchParams(window.location.search);
    const step = urlParams.get('step');
    if (step) {
      this.currentStep = step
      this.prevButtonTarget.classList.remove('hidden')
    }
    this.showCurrentStep()
  }

  toggleSlideIn() {
    if (this.slideInContainerTarget.classList.contains('hidden')) {
      this.slideInTriggerTarget.classList.toggle('rotate-180')
      enter(this.slideInContainerTarget)
    } else {
      leave(this.slideInContainerTarget)                
      this.slideInTriggerTarget.classList.toggle('rotate-180')
    }
  }

  previous() {
    this.currentStep--
    this.updateStep()
    this.showCurrentStep()
    // this.nextButtonTarget.classList.remove('hidden')
    // this.finishButtonTarget.classList.add('hidden')
  }

  next() {
    if (this.formExists(this.currentStep)) {
      alert('Please save before continuing.')
    } else {
      this.currentStep++
      this.updateStep()
      this.showCurrentStep()         
    }
  }

  showCurrentStep() { 
    this.displayActions()
    this.setProgressBar()
  }

  displayActions() {
    if (this.currentStep == this.lastStep) {
      this.prevButtonTarget.classList.remove('hidden')
      this.nextButtonTarget.classList.add('hidden')
    } else {
      if (this.currentStep == 1) {
        this.prevButtonTarget.classList.add('hidden')
      }
    }
  }

  setProgressBar() {
    this.progressBarTarget.style.width = `${(this.currentStep / this.lastStep) * 100}%`
  }

  formExists(stepNumber) {
    var step_id = 'step' + stepNumber
    var element = document.getElementById(step_id);
    if (element) {
      return true;
    } else {
      return false;
    }
  }  

  updateStep() {
    // grab the current url and update the step param to the current step
    var url = new URL(window.location.href);
    url.searchParams.set('step', this.currentStep);
    window.location.href = url
  }
}