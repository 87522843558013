// app/javascript/controllers/broadcast_time_controller.js
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["time"]

  initialize() {
    this.convertBroadcastTimes();
  }

  connect() {
    this.convertBroadcastTimes();
    
    this.element.addEventListener('DOMContentLoaded', this.convertBroadcastTimes);
    this.element.addEventListener('turbo:load', this.convertBroadcastTimes);
  }

  disconnect() {
    this.element.removeEventListener('DOMContentLoaded', this.convertBroadcastTimes);
    this.element.removeEventListener('turbo:load', this.convertBroadcastTimes);
  }

  convertBroadcastTimes = () => {
    this.timeTargets.forEach((element) => {
      const timeString = element.dataset.timeString;
      const localTime = new Date(timeString).toLocaleString();
      element.textContent = `Sent on ${localTime}`;
    });
  }
}
