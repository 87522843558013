import { Controller } from "@hotwired/stimulus";
import { post } from '@rails/request.js';

export default class extends Controller {
    static targets = [];

    connect() {}

    async show_correct_field_options(event) {
      post('/contacts/show_contact_field_options', { body: {field_type: event.target.value}, responseKind: 'turbo-stream' });
    }

}