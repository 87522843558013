// Controls activity for inactive channels
// Use channel_controller.js for active channel functions

import { Controller } from "@hotwired/stimulus";
import consumer from "../channels/consumer"

export default class extends Controller {
  static targets = ["mentions"]

  connect() {
    this.usersName = document.querySelector("meta[name='name']").getAttribute("content")
    this.subscription = consumer.subscriptions.create({ channel: "UnreadsChannel", id: this.data.get("id") }, {
      connected: this._connected.bind(this),
      disconnected: this._disconnected.bind(this),
      received: this._received.bind(this)
    })
  }

  disconnect() {
    consumer.subscriptions.remove(this.subscription)
  }

  _connected() {
  }

  _disconnected() {
  }

  _received(data) {
    this.element.classList.add('font-bold')

    if (data.mentions && data.mentions.includes(this.usersName)) {
      let count = parseInt(this.mentionsTarget.textContent)
      this.mentionsTarget.textContent = count + 1
      this.mentionsTarget.classList.remove("hidden")
      // Consider adding a notication to the user
    }
  }
}
