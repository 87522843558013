import { Controller } from "@hotwired/stimulus";
import Uppy from "@uppy/core";
// import ProgressBar from "@uppy/progress-bar";
import Dashboard from "@uppy/dashboard";
import ActiveStorageUpload from "@excid3/uppy-activestorage-upload";
import ImageEditor from "@uppy/image-editor";
import Webcam from "@uppy/webcam";
// import ScreenCapture from "@uppy/screen-capture";

export default class extends Controller {
  static targets = [ "element", "form" ]

  connect() {
    this.direct_upload_url = document.querySelector("meta[name='direct-upload-url']").getAttribute("content")
    this.elementTargets.forEach(element => {
      this.setupUppy(element)
    })
  }

  setupUppy(element) {    
    // If you want to submit form with ajax on complete rather than show preview
    // let form = element.closest('form')
    let field_name = element.dataset.uppyField
    let type = element.dataset.uppyType

    let uppy = Uppy({
      autoProceed: false,
      allowMultipleUploads: false,
      logger: Uppy.debugLogger
    }).use(ActiveStorageUpload, {
      directUploadUrl: this.direct_upload_url
    })
    
    this.trigger = element.querySelector('[data-behavior="uppy-trigger"]')
    this.trigger.addEventListener("click", (event) => event.preventDefault())
    uppy.use(Dashboard, {
      trigger: this.trigger,
      closeAfterFinish: true,
      proudlyDisplayPoweredByUppy: false,
      theme: 'dark',
      autoOpenFileEditor: true,
      closeModalOnClickOutside: true
    })
    
    switch (type) {
      case "avatar":
        uppy = this.uppyAvatar(uppy)
        break;
      case "landscape":
        uppy = this.uppyLandscape(uppy)
        break;
      case "portrait":
        uppy = this.uppyPortrait(uppy)
        break;
      case "audio":
        uppy = this.uppyAudio(uppy)
        break;
      default:
        break;
    }
  
    if (uppy !== "") {
      uppy.on('complete', (result) => {
        element.querySelectorAll('[data-pending-upload]').forEach(element => element.parentNode.removeChild(element))
    
        result.successful.forEach(file => {
          this.appendUploadedFile(element, file, field_name)
          this.setPreview(element, file)
        })
    
        // uppy.reset()
      })
    }    
  }

  uppyAvatar(uppy) {
    uppy.use(ImageEditor, { 
      target: Dashboard,
      quality: 0.8,
      cropperOptions: {
        viewMode: 0,
        background: false,
        autoCropArea: 0.8,
        responsive: true,
        aspectRatio: 1/1,
      },
      actions: {
        revert: false,
        rotate: false,
        granularRotate: true,
        flip: false,
        zoomIn: true,
        zoomOut: true,
        cropSquare: false,
        cropWidescreen: false,
        cropWidescreenVertical: false,
      }
    })

    // .use(Webcam, {
    //   onBeforeSnapshot: () => Promise.resolve(),
    //   target: Dashboard,
    //   countdown: false,
    //   modes: [
    //     'picture'
    //   ]
    // })
    return uppy;
  }

  uppyLandscape(uppy) {
    uppy.use(ImageEditor, { 
      target: Dashboard,
      cropperOptions: {
        viewMode: 0,
        background: false,
        autoCropArea: 1,
        responsive: true,
        aspectRatio: 16/9,
      },
      actions: {
        revert: false,
        rotate: false,
        granularRotate: true,
        flip: false,
        zoomIn: true,
        zoomOut: true,
        cropSquare: false,
        cropWidescreen: true,
        cropWidescreenVertical: false,
      }
    })
    return uppy;
  }

  uppyPortrait(uppy) {
    uppy.use(ImageEditor, { 
      target: Dashboard,
      cropperOptions: {
        viewMode: 0,
        background: false,
        autoCropArea: 1,
        responsive: true,
        aspectRatio: 9/16,
      },
      actions: {
        revert: false,
        rotate: false,
        granularRotate: true,
        flip: false,
        zoomIn: true,
        zoomOut: true,
        cropSquare: false,
        cropWidescreen: false,
        cropWidescreenVertical: true,
      }
    })
    return uppy;
  }

  uppyAudio(uppy) {
    uppy.use(Webcam, {
      onBeforeSnapshot: () => Promise.resolve(),
      target: Dashboard,
      modes: [
        'audio-only'
      ]
    })
    return uppy;
  }

  appendUploadedFile(element, file, field_name) {
    const hiddenField = document.createElement('input')

    hiddenField.setAttribute('type', 'hidden')
    hiddenField.setAttribute('name', field_name)
    hiddenField.setAttribute('data-pending-upload', true)
    hiddenField.setAttribute('value', file.response.signed_id)

    element.appendChild(hiddenField)
  }

  setPreview(element, file) {
    let preview = element.querySelector('[data-behavior="uppy-preview"]')
    if (preview) {
      let src = (file.preview) ? file.preview : "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSpj0DBTVsaja01_xWh37bcutvpd7rh7zEd528HD0d_l6A73osY"
      preview.src = src
    }
  }
}


////////// Possible solution for webcam being a weird file and not saving
// I discovered a bit of a gotcha in that file data added to the Uppy dashboard via normal file picker interface and the webcam are slightly different. I had to do the following in my Uppy core initializer to add a file name to the blobs that are generated via the webcam. The blob data doesn't have a name attribute but it needs one.

// let yourUppyInstance = Uppy({
//         id: 'document',
//         // set file name for blob captured from webcam
//         onBeforeFileAdded: (file, files) => {
//                 if(!file.data.name) {
//                         file.data.name = file.name
//                         }
//                 return file
//         }
// })