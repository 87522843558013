// Controls activity for active channels
// Use unreads_controller.js for inactive channel functions

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "messages" ]
  
  connect() {
  }

  markAsRead() {
    this.subscription.perform("touch")
  }

  scrollToBottom() {
    // this.messagesTarget.scrollTo(0,this.messagesTarget.scrollHeight)
    var isScrolled = this.messagesTarget.scrollTop == this.messagesTarget.scrollHeight - this.messagesTarget.offsetHeight;
    if (isScrolled) {
      this.messagesTarget.scrollTop = this.messagesTarget.scrollHeight;
    }
  }
}