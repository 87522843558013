import { Controller } from "@hotwired/stimulus";
import { enter, leave } from 'el-transition';
import { post } from '@rails/request.js';

export default class extends Controller {
  static targets = [ "layout", "overlayTrigger", "overlay", "footer", "mainContainer", "panel", "tabs", "actionMenu", "actionMenuToggle", "btn", "navContainer", "userNavContainer", "userProfileMenuTrigger"]
  
  connect() {    
    // console.log('layout controller connected')
  }
  
  toggleOverlay(event) {
    // Get the name of the overlay triggered
    var overlay_name = event.currentTarget.dataset.overlay
    var menu_name = event.currentTarget.dataset.menu
    var overlay_off = true
    document.getElementById("overlayTitle").innerHTML = event.currentTarget.dataset.menu

    if (overlay_name == "userNav" || overlay_name == "fullscreen_menu") {      
      this.navContainerTarget.classList.add('hidden')
      this.userProfileMenuTriggerTarget.classList.add('hidden')
    }

    if (overlay_name == "fullscreen_menu") {      
      this.userNavContainerTarget.classList.toggle('translate-y-full')
      this.userNavContainerTarget.classList.toggle('z-[100]')
      this.userNavContainerTarget.classList.toggle('z-[60]')
      document.getElementById("fullscreenMenu").classList.toggle('z-[70]')
    }
  
 
      

    
    if (this.overlayTarget.classList.contains('hidden')) {
      if(menu_name == "Direct Messages"){
        post('/dms/get_threads', { body: {}, responseKind: 'turbo-stream' });
      }

      // Set the target overlay to display
      this.overlayTargets.forEach(overlay => {      
        if (overlay.dataset.overlay == overlay_name.replace(/\d+/g, '')) {        
          document.body.classList.add('overflow-hidden')        
          if (overlay.classList.contains('hidden')) {
            this.layoutTarget.classList.add(overlay.dataset.overlay)
            enter(overlay) 
            overlay_off = false           
          } else {
            leave(overlay)
            this.layoutTarget.classList.remove(overlay.dataset.overlay)     
          }
        } else {
          leave(overlay)
          overlay.classList.add('hidden')
          this.layoutTarget.classList.remove(overlay.dataset.overlay)        
        }

        if (overlay_off) { document.body.classList.remove('overflow-hidden') }      
      });
    }
  }

  closeOverlay(event) {    
    this.navContainerTarget.classList.remove('hidden')
    this.userNavContainerTarget.classList.remove('translate-y-full')
    this.userNavContainerTarget.classList.remove('z-[100]')
    this.userNavContainerTarget.classList.add('z-[60]')
    document.getElementById("fullscreenMenu").classList.remove('z-[70]')
    this.userProfileMenuTriggerTarget.classList.remove('hidden')
    
    this.overlayTargets.forEach(overlay => {
      leave(overlay)
      if(overlay == "fullscreenMenu") {
        document.querySelector("input[name='query']").value = "";
      }
    })
    this.layoutTarget.classList.remove('fullscreen_menu','account','account_switcher','inbox','dms')
    
    // Needs to be delayed to avoid scrollbar jump
    setTimeout(() => {      
      document.body.classList.remove('overflow-hidden')
    }, 100);    
  }

  togglePanel(event) {
    if (this.panelTarget.classList.contains('hidden')) {
      enter(this.panelTarget) 
    } else {
      leave(this.panelTarget)
    }
  }

  toggleTabs(event) {
    if (this.tabsTarget.classList.contains('hidden')) {
      enter(this.tabsTarget)
      event.currentTarget.innerHTML = `<svg style="outline" class="text-white h-6 w-6" aria-hidden="true" viewBox="0 0 24 24" height="24" width="24" version="1.1" fill="none" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" /></svg>`
    } else {
      leave(this.tabsTarget)
      event.currentTarget.innerHTML = `<svg style="outline" class="text-gray-300 h-6 w-6" aria-hidden="true" viewBox="0 0 24 24" height="24" width="24" version="1.1" fill="none" stroke="currentColor"><path d="M5 11L12 4L19 11M5 19L12 12L19 19" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>`
    }
  }

  toggleActionMenu() {
    this.mainContainerTarget.classList.toggle('hidden')
    this.actionMenuTarget.classList.toggle('hidden')
    this.mainContainerTarget.classList.toggle('opacity-25')
    this.actionMenuToggleTarget.classList.toggle('open')       
    if(document.getElementById("userNavContainer")) {
      document.getElementById("userNavContainer").classList.toggle('translate-y-full')
    }    
  } 
}