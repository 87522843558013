import { Controller } from "@hotwired/stimulus"
import DailyIframe from '@daily-co/daily-js';

// Connects to data-controller="daily"
export default class extends Controller {
  static targets = [ 'iframe' ]

  connect() {        
    this.startCall();
  }

  startCall(event) {
    let callFrame = DailyIframe.wrap(this.iframeTarget);
    callFrame.join({ url: 'https://flexx.daily.co/QigS2yG83k6mKKtqLpwZ' });
  }
}
