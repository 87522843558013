import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "username", "availability", "profileUrl", "availabilityStatus" ]

  connect() {
    this.ogUsername = this.usernameTarget.value
    this.form = this.element
    
    if (!this.checkAvailability) {
      this.form.classList.add("xx__disabled")
    }
  }

  checkAvailability() {
    const name = this.usernameTarget.value
    this.availabilityStatusTarget.classList.remove('hidden')
    
    if (name == this.ogUsername) {
      this.form.classList.remove("xx__disabled")
      this.profileUrlTarget.innerHTML = `${name}`
      this.availabilityTarget.classList.add("hidden")
      this.availabilityStatusTarget.classList.add('hidden')
    } else if (name !== "") { 
      if (name.length < 3) {
        this.availabilityTarget.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" class="fill-current icon text-green-500 inline-block" role="img" aria-labelledby="a7x5ltsvjjj243421kvvlbytvsipjka3"><title id="a7x5ltsvjjj243421kvvlbytvsipjka3">Icons/checkmark outline</title><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM6.7 9.29L9 11.6l4.3-4.3 1.4 1.42L9 14.4l-3.7-3.7 1.4-1.42z"></path></svg>`
        this.availabilityTarget.classList.add("hidden")
      } else {
        fetch(`/username_taken/${name}`, {
          headers: { accept: 'application/json'}
        }).then((response) => response.text())
        .then(data => {
          this.availabilityTarget.classList.remove("hidden")
          if (data == "false") {
            this.availabilityTarget.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" class="fill-current icon text-green-500 inline-block" role="img" aria-labelledby="a7x5ltsvjjj243421kvvlbytvsipjka3"><title id="a7x5ltsvjjj243421kvvlbytvsipjka3">Icons/checkmark outline</title><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM6.7 9.29L9 11.6l4.3-4.3 1.4 1.42L9 14.4l-3.7-3.7 1.4-1.42z"></path></svg>`
            this.form.classList.remove("xx__disabled")
            this.profileUrlTarget.innerHTML = `${name}`
            this.availabilityStatusTarget.classList.add('hidden')
          } else {
            this.availabilityTarget.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" class="fill-current icon text-red-500 inline-block" role="img" aria-labelledby="adouwmcsvffeztrvgxntigtzj9vdj14o"><title id="adouwmcsvffeztrvgxntigtzj9vdj14o">Icons/close outline</title><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm1.41-1.41A8 8 0 1 0 15.66 4.34 8 8 0 0 0 4.34 15.66zm9.9-8.49L11.41 10l2.83 2.83-1.41 1.41L10 11.41l-2.83 2.83-1.41-1.41L8.59 10 5.76 7.17l1.41-1.41L10 8.59l2.83-2.83 1.41 1.41z"></path></svg>`                 
            this.availabilityStatusTarget.classList.add('hidden') 
            this.form.classList.add("xx__disabled")     
          }
        })
      }            
    } else {
      this.availabilityTarget.classList.add("hidden")
      this.availabilityStatusTarget.classList.add('hidden')
    }    
  }
}