import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [ "form", "input", "clear" ]

  connect() {
    this.clearTarget.classList.add('hidden')
  }

  search() {
    clearTimeout(this.timeout)
    this.showClearButton()

    this.timeout = setTimeout(() => {
      Rails.fire(this.formTarget, 'submit')
    }, 200)
    
    if(this.inputTarget.value.trim() == "") {
      this.hideClearButton()      
    }   
  }

  showClearButton() {    
    this.clearTarget.classList.remove('hidden')
  }

  hideClearButton() {    
    this.clearTarget.classList.add('hidden')
  }

  clear() {
    this.inputTarget.value = ""
    this.clearTarget.classList.add('hidden')
    this.search()
  }

  showOriginalResults() {

  }
}