import { Controller } from "@hotwired/stimulus";
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
import { DirectUpload } from "@rails/activestorage"
import { post } from '@rails/request.js';

export default class extends Controller {
  static targets = []
  card_front_blob = null;
  card_back_blob = null;

  connect() {
    
  }

  disconnect() {

  }
  dataURLtoBlob(dataUrl) {
    var arr = dataUrl.split(','), mime = arr[0].match(/:(.*?);/)[1];
    var bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], {type:mime});
  }

  get csrfToken() {
    const element = document.head.querySelector('meta[name="csrf-token"]')
    return element.getAttribute("content")
  }

  async submitForm(event) {
    event.preventDefault(); // Prevents the default form submission

    // Access form data
    const formData = new FormData(this.element);

    await this.generateImage("card-front", true);
    await this.generateImage("card-back", false);

    // Add the image data
    formData.append("flexx_card[front_image]", this.card_front_blob, "front_image.png");
    formData.append("flexx_card[back_image]", this.card_back_blob, "back_image.png");

    // Send POST request to your Rails controller action
    fetch("/flexx_cards", {
      method: "POST",
      body: formData,
      headers: {
        "X-CSRF-Token": this.csrfToken
      }, 
      responseKind: 'turbo-stream'
    })
    .then(response => response.json())
    .then(data => {
      console.log(data);
      // Handle success
    })
    .catch(error => {
      console.error('Error:', error);
      // Handle error
    });
  }

  async generateImage(cardId, front) {
    const element = document.getElementById(cardId);

    await htmlToImage.toPng(element, { backgroundColor: '#ffffff' })
      .then(async (dataUrl) => {
        
        // Convert Data URL to Blob
        const blob = this.dataURLtoBlob(dataUrl);
        if (front){
          this.card_front_blob = blob;
        } else {
          this.card_back_blob = blob;
        }
      })
      .catch((error) => {
        console.error('oops, something went wrong!', error);
      });
  }

  // async generateImage(event) {
  //   var button = event.currentTarget;
  //   const id = button.getAttribute('data-flexx-card-id-value');
  //   const element = document.getElementById(id);
  //   const direct_upload_url = document.querySelector("meta[name='direct-upload-url']").getAttribute("content");



  //   htmlToImage.toPng(element, { backgroundColor: '#ffffff' })
  //     .then((dataUrl) => {
        
  //       // Convert Data URL to Blob
  //       const blob = this.dataURLtoBlob(dataUrl);

  //       let formData = new FormData();
  //       formData.append('front_image', blob, 'front_image.png');

  //       post('/flexx_cards/' + id + '/set_front_image', {
  //         body: formData,
  //         responseKind: 'turbo-stream',
  //         headers: {
  //           "X-CSRF-Token": this.csrfToken
  //         }, responseKind: 'turbo-stream'
  //       });
  //     })
  //     .catch((error) => {
  //       console.error('oops, something went wrong!', error);
  //     });
  // }

}
