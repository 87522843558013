import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = [];
    element = null;
    modalCheckbox = null;
    newContent = null;

    connect() {}
    insertTextSnippet(event) {
        this.element = document.querySelector("trix-editor");
        this.modalCheckbox = document.getElementById("textSnippetChooserModal");

        //Insert text snippet wherever the caret is
        if(event.params.sms){
            this.element.editor.insertString(event.params.snippet);
        } else {
            this.element.editor.insertHTML(event.params.snippet);
        }

        //Close the modal
        this.modalCheckbox.checked = false;

        //This puts the focus on the editor,
        // so the user can click a merge field and then keep typing
        this.element.editor.element.focus();
    }

    setTextSnippetToSave(event) {
        this.element = document.querySelector("trix-editor");
        this.newContent =  document.getElementById("newSnippetContent");
        this.modalCheckbox = document.getElementById("textSnippetSaverModal");
        if (this.modalCheckbox.checked) {
            var textString = this.element.editor.getDocument().toString();
            //For some reason, it always appends a \n.  Strip it
            textString = textString.slice(0, textString.length - 1);

            this.newContent.value = textString;
        }
    }

    insertMergeField (event){
      if(event.params.sms){
        const smsBody = document.getElementById("sms-body");
        
        smsBody.insertString(event.params.field);
        smsBody.focus();
      } else {
        this.trix = document.querySelector("trix-editor");
        const emailBody = this.trix.editor;
  
        //Insert merge field wherever the caret is
        emailBody.insertString(event.params.field);
      }
    }
}
