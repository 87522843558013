import { Controller } from "@hotwired/stimulus";
import { post } from '@rails/request.js';

export default class extends Controller {
    static targets = [];

    connect() {}

    async applyFilter(params) {
      console.log("Here are the params");
      console.log(params);

      post('/contacts/apply_filter', { body: params, responseKind: 'turbo-stream' });
    }

}