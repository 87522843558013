import { Controller } from '@hotwired/stimulus';
import { get, post } from '@rails/request.js';

export default class extends Controller {
  static targets = []
  
  connect() {
    console.log("par stimulus controller")
  }

  prepareSetIngredient (e) {
    get('/releases/set_ingredient/'+ e.params.ingredientId, {responseKind: 'turbo-stream' }); 
  }

  chooseImage (e) {
    post('/releases/update_ingredient', { body: {id: e.params.ingredientId, source_id: e.params.mediumId}, responseKind: 'turbo-stream' }); 
  }

  chooseVideo (e) {
    post('/releases/update_ingredient', { body: {id: e.params.ingredientId, source_id: e.params.mediumId}, responseKind: 'turbo-stream' }); 
  }

  chooseLink (e) {
    post('/releases/update_ingredient', { body: {id: e.params.ingredientId, source_id: e.params.linkId}, responseKind: 'turbo-stream' }); 
  }

  chooseLinks (e) {
    post('/releases/update_ingredient', { body: {id: e.params.ingredientId, value: e.params.selectedLinks}, responseKind: 'turbo-stream' }); 
  }

  chooseForm (e) {
    post('/releases/update_ingredient', { body: {id: e.params.ingredientId, source_id: e.params.formId}, responseKind: 'turbo-stream' }); 
  }

}