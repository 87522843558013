import { Controller } from '@hotwired/stimulus';
import { enter, leave } from 'el-transition';

export default class extends Controller {
  static targets = [ 'offCanvasMenu', 'offCanvasBackdrop', 'offCanvasContainer', 'slideInContainer', 'slideInTrigger' ]
  
  connect() {
  }

  toggleOffCanvas() {
    if (this.offCanvasMenuTarget.classList.contains('hidden')) {
      enter(this.offCanvasMenuTarget)
      enter(this.offCanvasBackdropTarget)
      enter(this.offCanvasContainerTarget)
    } else {
      leave(this.offCanvasMenuTarget)
      leave(this.offCanvasBackdropTarget)
      leave(this.offCanvasContainerTarget)
    }    
  }

  toggleSlideIn() {
    if (this.slideInContainerTarget.classList.contains('hidden')) {
      this.slideInTriggerTarget.classList.toggle('rotate-180')
      enter(this.slideInContainerTarget)
    } else {
      leave(this.slideInContainerTarget)                
      this.slideInTriggerTarget.classList.toggle('rotate-180')
    }
  }
}