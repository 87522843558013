import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [  ]
    upgrade_modal = null;
    connect() {

    }

    hideUpgradeModal (event){
        this.upgrade_modal = document.querySelector('#upgradeModal');
        this.upgrade_modal.checked = false;
    }
}
