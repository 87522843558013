import { Controller } from '@hotwired/stimulus';
import { enter, leave } from 'el-transition';
import { get } from '@rails/request.js';

export default class extends Controller {
  static targets = [ 'offCanvasMenu', 'offCanvasBackdrop', 'offCanvasContainer', 'slideInContainer', 'slideInTrigger', 'editableField', 'pagePreviewFrame', 'srcdoc', 'deviceContainer', 'buildContainer', 'htmlEditor', 'editorOverlay', 'editorFieldContainer', 'tabsNav', 'tabsContent', 'chooseTabButton' ]
  
  connect() {
    this.iframe = this.pagePreviewFrameTarget
    //this.updateIframe()
    this.setViewDevice()   
    this.deviceContainerTarget.classList.remove('hidden')
  }

  closeKitchen () {
    document.querySelector("#ingredientEditorModal").checked = false;
  }

  toggleOffCanvas() {
    if (this.offCanvasMenuTarget.classList.contains('hidden')) {
      enter(this.offCanvasMenuTarget)
      enter(this.offCanvasBackdropTarget)
      enter(this.offCanvasContainerTarget)
    } else {
      leave(this.offCanvasMenuTarget)
      leave(this.offCanvasBackdropTarget)
      leave(this.offCanvasContainerTarget)
    }    
  }

  toggleTabsDisplay() {
    if (this.tabsNavTarget.classList.contains('hidden')) {
      this.chooseTabButtonTarget.click();
    }
    this.tabsNavTarget.classList.toggle('hidden')
    this.tabsContentTarget.classList.toggle('hidden')
  }

  toggleSlideIn() {
    if (this.slideInContainerTarget.classList.contains('hidden')) {
      this.slideInTriggerTarget.classList.toggle('rotate-180')
      enter(this.slideInContainerTarget)
    } else {
      leave(this.slideInContainerTarget)                
      this.slideInTriggerTarget.classList.toggle('rotate-180')
    }
  }

  toggleBlock(event) {
    //Need to call an endpoint that shows/hides the block and rebuilds the page
    console.log("Toggle block: " + event.params.blockId);
    get('/build/pages/toggle_block/'+event.params.blockId, { responseKind: 'turbo-stream' })
  }

  toggleIngredient(event) {
    //Need to call an endpoint that shows/hides the ingredient and rebuilds the page
    console.log("Toggle ingredient: " + event.params.ingredientId);
    get('/build/pages/toggle_ingredient/'+ event.params.ingredientId, { responseKind: 'turbo-stream' })
  }

  toggleEditable(event) {
    document.querySelector('#kitchen').innerHTML = `<progress class="progress w-56"></progress>`;
    document.querySelector('#ingredientEditorModal').checked = true;
    get('/build/pages/edit_ingredient/'+event.params.ingredientId, { responseKind: 'turbo-stream' });
    
    /*    
    // Select all fields to be made editable in iframe
    const editableFields = this.iframe.contentWindow.document.querySelectorAll('.xx__editableField');

    let editing = null
    if (this.buildContainerTarget.classList.contains('xx__content-editing')) {
      editing = false      
    } else {
      editing = true
    }    

    if (editing) {
      editableFields.forEach(field => {                     
        field.querySelectorAll('span[data-editable]').forEach((span,index) => {
          let span_id = field.getAttribute('data-field') + '--text_' + index
          span.contentEditable = true
          span.setAttribute('id', span_id);
        })     
        var editableElement = field.cloneNode(true);   
        this.editorFieldContainerTarget.appendChild(editableElement);               
      })
    } else {
      this.updateIframe()
      this.editorFieldContainerTarget.innerHTML = ''
    }

    this.buildContainerTarget.classList.toggle('xx__content-editing')
    this.editorOverlayTarget.classList.toggle('hidden')
    */
  }

  updateField() {
    // For each span that has a contenteditable="true" in editorFieldContainerTarget
    this.editorFieldContainerTarget.querySelectorAll('[data-editable="text"]').forEach(span => {
      // use the id of the span to get the matching iframe element
      var iframeElement = this.iframe.contentWindow.document.getElementById(span.id);
      // to inject the the text content into the iframe element with a matching id
      iframeElement.innerHTML = span.innerHTML;
    })
    this.buildContainerTarget.classList.toggle('xx__content-editing')
    this.editorOverlayTarget.classList.toggle('hidden')
    // this.editorFieldContainerTarget.innerHTML = ''
  }

  updateIframe() {
    this.iframe.srcdoc = `<script src="https://cdn.tailwindcss.com/"></script>
                            <div class="bg-base-100 flex h-screen items-center justify-center w-screen">
                              <svg class="animate-spin -ml-1 mr-3 h-7 w-7 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                              </svg>
                            </div>`

    if (this.srcdocTarget.value) {
      setTimeout(() => {      
        this.iframe.srcdoc = this.srcdocTarget.value
      }, 700);
    } else {
      this.iframe.srcdoc = `<script src="https://cdn.tailwindcss.com/"></script>
                            <div class="bg-base-100 flex pt-12 pr-2 items-center justify-center w-screen">
                              <p class="text-2xl">Choose a template to start.</p>
                            </div>`
    }
  }

  setViewDevice(event) {      
    // Default to mobile view
    let selectedDevice = 'mobile'
    // If a device button is clicked, set device
    if (event) {
      selectedDevice = event.currentTarget.dataset.device
    } 
    
    // Adjust css class on build container to show active device
    this.buildContainerTarget.classList.remove('mobile','desktop')
    this.buildContainerTarget.classList.add(selectedDevice)

    let deviceContainer = this.deviceContainerTarget
    let activeDeviceClass = 'active--' + selectedDevice

    // Hide the current device preview
    deviceContainer.classList.add('scale-70','opacity-0')

    // Set the width to the device
    setTimeout(() => {      
      deviceContainer.classList.add(activeDeviceClass)
      if (selectedDevice == 'desktop') {
        deviceContainer.classList.remove('active--mobile')
      } else {            
        deviceContainer.classList.remove('active--desktop')
      }
    }, 200);

    // Show the new device preview
    setTimeout(() => {
      deviceContainer.classList.remove('scale-70','opacity-0')
    }, 500);
  }

  // Toggle the view of the editor for custom HTML
  toggleEditHtml() {
    this.htmlEditorTarget.classList.toggle('hidden')
  }
}