// Customizable command palette for advanced users
// Opens with cmd+k or ctrl+k by default
// https://github.com/excid3/ninja-keys

import { Controller } from "@hotwired/stimulus"
import "@gorails/ninja-keys"

export default class extends Controller {
  connect() {
    this.element.data = [      
      {
        id: "Brand",
        title: "Brand menu",
        children: ['HQ', 'Build', 'Connect'],
        hotkey: "ctrl+B",
        icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="ninja-icon" viewBox="0 0 640 512"><defs><style>.fa-secondary{opacity:.4}</style></defs><path class="fa-primary" d="M640 191.1v191.1c0 35.25-28.75 63.1-64 63.1h-32v54.24c0 7.998-9.125 12.62-15.5 7.873l-82.75-62.12L319.1 447.1C284.7 447.1 256 419.2 256 383.1v-31.98l96-.002c52.88 0 96-43.12 96-95.99V128h128C611.3 128 640 156.7 640 191.1z"/><path class="fa-secondary" d="M352 0H64C28.75 0 0 28.75 0 63.1V256C0 291.2 28.75 320 64 320l32 .0098v54.25c0 7.998 9.125 12.62 15.5 7.875l82.75-62.12L352 319.9c35.25 .125 64-28.68 64-63.92V63.1C416 28.75 387.3 0 352 0z"/></svg>',
        handler: () => {
          this.element.open({ parent: 'Brand' });
          return {keepOpen: true};
        }
      },
      {
        id: "HQ",
        title: "HQ",
        icon: '<svg xmlns="http://www.w3.org/2000/svg" class="ninja-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" /></svg>',
        parent: 'Brand',
        handler: () => {
          Turbo.visit("/hq")
        }
      },
      {
        id: "Build",
        title: "Build",
        icon: '<svg xmlns="http://www.w3.org/2000/svg" class="ninja-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" /></svg>',
        parent: 'Brand',
        handler: () => {
          Turbo.visit("/build")
        }
      },
      {
        id: "Connect",
        title: "Connect",
        icon: '<svg xmlns="http://www.w3.org/2000/svg" class="ninja-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" /></svg>',
        parent: 'Brand',
        handler: () => {
          Turbo.visit("/connect")
        }
      },
      {
        id: "Accounts",
        title: "Switch account",
        hotkey: "ctrl+A",
        icon: '<svg xmlns="http://www.w3.org/2000/svg" class="ninja-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" /></svg>',
        handler: () => {
          Turbo.visit("/accounts")
        }
      },
      {
        id: "Personal",
        title: "Personal menu",
        children: ['Workspace', 'Calendar', 'Messages', 'Notes'],
        hotkey: "ctrl+P",
        icon: '<svg xmlns="http://www.w3.org/2000/svg" class="ninja-icon h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>',
        handler: () => {
          this.element.open({ parent: 'Personal' });
          return {keepOpen: true};
        }
      },
      {
        id: "Workspace",
        title: "Workspace",
        hotkey: "ctrl+W",
        icon: '<svg xmlns="http://www.w3.org/2000/svg" class="ninja-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" /></svg>',
        parent: 'Personal',
        handler: () => {
          Turbo.visit("/")
        }
      },
      {
        id: "Calendar",
        title: "Calendar",
        hotkey: "ctrl+C",
        icon: '<svg class="ninja-icon" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="calendar-circle-user" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" data-fa-i2svg=""><path fill="currentColor" d="M112 0C120.8 0 128 7.164 128 16V64H320V16C320 7.164 327.2 0 336 0C344.8 0 352 7.164 352 16V64H384C419.3 64 448 92.65 448 128V192H32V448C32 465.7 46.33 480 64 480H296.2C306.3 492.1 317.9 502.9 330.8 512H64C28.65 512 0 483.3 0 448V128C0 92.65 28.65 64 64 64H96V16C96 7.164 103.2 0 112 0zM384 96H64C46.33 96 32 110.3 32 128V160H416V128C416 110.3 401.7 96 384 96zM488 344C488 374.9 462.9 400 432 400C401.1 400 376 374.9 376 344C376 313.1 401.1 288 432 288C462.9 288 488 313.1 488 344zM432 320C418.7 320 408 330.7 408 344C408 357.3 418.7 368 432 368C445.3 368 456 357.3 456 344C456 330.7 445.3 320 432 320zM288 368C288 288.5 352.5 224 432 224C511.5 224 576 288.5 576 368C576 447.5 511.5 512 432 512C352.5 512 288 447.5 288 368zM432 480C454.1 480 474.7 473.6 491.1 462.6C484.1 453.5 472.6 448 460.2 448H403.8C391.4 448 379.9 453.5 372 462.6C389.3 473.6 409.9 480 432 480H432zM460.2 416C482.1 416 502.4 425.6 516.3 441.8C533.5 422.1 544 396.2 544 368C544 306.1 493.9 256 432 256C370.1 256 320 306.1 320 368C320 396.2 330.5 422.1 347.7 441.8C361.6 425.6 381.9 416 403.8 416H460.2z"></path></svg>',
        parent: 'Personal',
        handler: () => {
          Turbo.visit("/dms")
        }
      },
      {
        id: "Messages",
        title: "Messages",
        hotkey: "ctrl+M",
        icon: '<svg class="ninja-icon" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="message-lines" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M447.1 0h-384c-35.25 0-64 28.75-64 63.1v287.1c0 35.25 28.75 63.1 64 63.1h96v83.1c0 9.838 11.03 15.55 19.12 9.7l124.9-93.7h144c35.25 0 64-28.75 64-63.1V63.1C511.1 28.75 483.2 0 447.1 0zM480 352c0 17.6-14.4 32-32 32h-144.1c-6.928 0-13.67 2.248-19.21 6.406L192 460v-60c0-8.838-7.164-16-16-16H64c-17.6 0-32-14.4-32-32V64c0-17.6 14.4-32 32-32h384c17.6 0 32 14.4 32 32V352zM272 240h-128c-8.801 0-16 7.189-16 15.99C127.1 264.8 135.2 272 144 272h128c8.801 0 15.1-7.213 15.1-16.01C287.1 247.2 280.8 240 272 240zM368 144h-224c-8.801 0-16 7.193-16 15.99C127.1 168.8 135.2 176 144 176h224c8.801 0 15.1-7.21 15.1-16.01C383.1 151.2 376.8 144 368 144z"></path></svg>',
        parent: 'Personal',
        handler: () => {
          Turbo.visit("/dms")
        }
      },
      {
        id: "Notes",
        title: "Notes",
        hotkey: "ctrl+N",
        icon: '<svg class="ninja-icon" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="notebook" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M416 0H128C92.65 0 64 28.65 64 64v48H16C7.166 112 0 119.2 0 128c0 8.832 7.166 16 16 16H64v96H16C7.166 240 0 247.2 0 256c0 8.832 7.166 16 16 16H64v96H16C7.166 368 0 375.2 0 384c0 8.832 7.166 16 16 16H64V448c0 35.35 28.65 64 64 64h288c35.35 0 64-28.65 64-64V64C480 28.65 451.3 0 416 0zM128 480c-17.64 0-32-14.36-32-32v-48h34.67c8.834 0 16-7.168 16-16c0-8.834-7.166-16-16-16H96v-96h34.67c8.834 0 16-7.168 16-16c0-8.834-7.166-16-16-16H96v-96h34.67c8.834 0 16-7.168 16-16c0-8.834-7.166-16-16-16H96V64c0-17.64 14.36-32 32-32h64v448H128zM448 448c0 17.64-14.36 32-32 32H224V32h192c17.64 0 32 14.36 32 32V448z"></path></svg>',
        parent: 'Personal',
        handler: () => {
          Turbo.visit("/notebooks")
        }
      }
    ];
  }
}
