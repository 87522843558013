import { Controller } from "@hotwired/stimulus";
import { useClickOutside } from "stimulus-use";

export default class extends Controller {
  static targets = ['content', 'search', 'back']

  connect() {    
    // Need to add an event listener to toggle the "hidden" class on the mobile nav drawer when the search input is focused    
    this.search_input = document.getElementById('searchEverything')
    this.mobileNavDrawer = document.getElementById('mobileNavDrawer')
    this.fullscreenMenu = document.getElementById('fullscreenMenu')
    this.searchCategoriesContainer = document.getElementById('searchCategoriesContainer')
    this.searchEverythingResults = document.getElementById('searchEverythingResults')
    // this.actionCategoriesContainer = document.getElementById('actionCategoriesContainer')

    this.search_input.addEventListener('focus', () => {      
      this.mobileNavDrawer.setAttribute('style', 'display: none !important')
      this.fullscreenMenu.setAttribute('style', 'padding-bottom: 0 !important')
      this.searchCategoriesContainer.setAttribute('style', 'display: none !important')
      this.searchEverythingResults.setAttribute('style', 'display: flex !important')
      // this.actionCategoriesContainer.classList.add('translate-y-full')
    })

    useClickOutside(this, { element: this.search_input })
  }

  openSearch(event) {
    // hide the main content
    this.contentTargets.forEach(content => {
      content.classList.toggle('hidden')
    })

    // open appropriate search form
    var search_name = event.currentTarget.dataset.search
    this.searchTargets.forEach(searchForm => {
      if (searchForm.dataset.search == search_name) {
        searchForm.classList.toggle('hidden')
      }
    })

    // show the back button
    this.backTarget.classList.toggle('hidden')
  }

  closeSearch() {
    // hide the back button
    this.backTarget.classList.add('hidden')

    // clear any search form input
    this.clearSearch()

    // hide any search form
    this.searchTargets.forEach(searchForm => {
      searchForm.classList.add('hidden')
    })

    // show the main content
    this.contentTargets.forEach(content => {
      if (content.classList.contains('hidden')) {
        content.classList.toggle('hidden')
      }
    })
  }

  clearSearch() {
    document.querySelector("input[name='query']").value = "";
  }

  clickOutside(event) {
    this.hideSearchResults()    
  }

  hideSearchResults() {
    // need to delay to avoid disruptuing the click event
    setTimeout(() => {
      this.mobileNavDrawer.removeAttribute('style', 'display')
      this.fullscreenMenu.removeAttribute('style', 'padding-bottom')
      this.searchCategoriesContainer.removeAttribute('style', 'display')
      this.searchEverythingResults.removeAttribute('style', 'display')
      // this.actionCategoriesContainer.classList.remove('translate-y-full')
    }, 250);
  }
}