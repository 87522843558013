import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="canvas"
export default class extends Controller { 
  static targets = [ "result" ] 
  connect() {
    let result = this.imageUrlToSvg("https://the-tebaldi-zone-images.s3.amazonaws.com/tripoint-logo-mark.png")
    this.resultTarget.innerHTML = result
  }

  imageUrlToSvg(imageUrl) {
    // Create a canvas element and draw the image onto it
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();
    img.src = imageUrl;
    ctx.drawImage(img, 0, 0);
  
    // Convert the canvas to an SVG string and return it
    return new XMLSerializer().serializeToString(canvas);
  }

  removeBackground(image) {
    // Create a canvas element and draw the image onto it
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();
    img.src = image;
    ctx.drawImage(img, 0, 0);
  
    // Get the image data from the canvas
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
  
    // Iterate over the image data and set the alpha value of
    // any pixels that are not skin-colored to 0 (transparent)
    for (let i = 0; i < imageData.data.length; i += 4) {
      const r = imageData.data[i];
      const g = imageData.data[i + 1];
      const b = imageData.data[i + 2];
      if (!this.isSkinColor(r, g, b)) {
        imageData.data[i + 3] = 0;
      }
    }
  
    // Put the modified image data back onto the canvas
    ctx.putImageData(imageData, 0, 0);
  
    // Convert the canvas to a data URL and return it
    return canvas.toDataURL();
  }
  
  isSkinColor(r, g, b) {
    // Use some heuristics to determine if a pixel is skin-colored
    // This function is not perfect and may need to be fine-tuned
    // depending on the specific images you are working with
    return r > 95 && g > 40 && b > 20 && Math.max(r, g, b) - Math.min(r, g, b) > 15 && Math.abs(r - g) > 15;
  }
}
