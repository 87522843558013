import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['popup', 'input']

  showPopup() {
    this.popupTarget.style.display = 'block'
  }

  submit(event) {
    event.preventDefault()
    const input = this.inputTarget.value

    Rails.ajax({
      url: '/capture',
      type: 'POST',
      data: `input=${encodeURIComponent(input)}`,
      success: (response) => {
        // Handle the response, update the UI, or show a success message
        this.popupTarget.style.display = 'none'
      },
    })
  }
}