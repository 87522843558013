// app/javascript/controllers/transcription_controller.js

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["recordingsList"]
  static mediaRecorder = null
  static audioChunks = []

  connect() {
    this.loadRecordingsFromLocalStorage()
    this.mediaRecorder = null
    this.audioChunks = []

    this.loadRecordingsFromLocalStorage()
    this.recordingsListTarget.addEventListener("change", this.handleCheckboxChange.bind(this))
  }

  startRecording() {
    navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
      this.mediaRecorder = new MediaRecorder(stream)
      this.mediaRecorder.start()
  
      this.mediaRecorder.addEventListener("dataavailable", event => {
        this.audioChunks.push(event.data) // Now 'this' refers to the class instance
      })
    })
    this.showRecordingVisualization();
  }  

  stopRecording() {
    if (this.mediaRecorder) {
      this.mediaRecorder.addEventListener("stop", () => {
        const audioBlob = new Blob(this.audioChunks, { type: "audio/wav" })
        this.saveRecordingToLocalStorage(audioBlob)
        this.audioChunks = []
      })

      this.mediaRecorder.stop()
    }
    this.hideRecordingVisualization();
  }

  showRecordingVisualization() {
    document.getElementById('audioRecordingVisualization').classList.remove("hidden");
    document.getElementById('audioRecordButton').classList.add("hidden");
    document.getElementById('audioRecordStopButton').classList.remove("hidden");
  }

  hideRecordingVisualization() {
    document.getElementById('audioRecordingVisualization').classList.add("hidden");
    document.getElementById('audioRecordButton').classList.remove("hidden");
    document.getElementById('audioRecordStopButton').classList.add("hidden");
  }

  selectAll() {
    const checkboxes = this.recordingsListTarget.querySelectorAll(".upload-checkbox");
    const allChecked = Array.from(checkboxes).every(checkbox => checkbox.checked);
  
    checkboxes.forEach(checkbox => {
      checkbox.checked = !allChecked;
    });
  
    this.handleCheckboxChange();
  }
  
  playRecording(event) {
    const audioBlob = this.getRecordingFromLocalStorage(event.target.dataset.index);
    if (audioBlob) {
      const audioUrl = URL.createObjectURL(audioBlob);
      const audio = new Audio(audioUrl);
      event.target.audio = audio;
  
      const recordingItem = event.target.closest(".recording-item");
      const timeDisplay = recordingItem.querySelector('.time-display');
  
      audio.addEventListener('loadedmetadata', () => {
        const duration = audio.duration;
        timeDisplay.innerText = this.formatTime(0) + ' / ' + this.formatTime(duration);
      });
  
      audio.addEventListener('timeupdate', () => {
        const currentTime = audio.currentTime;
        timeDisplay.innerText = this.formatTime(currentTime) + ' / ' + this.formatTime(audio.duration);
      });
  
      this.togglePlayPause(event.target);
    }
  }
  
  formatTime(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return minutes.toString().padStart(2, '0') + ':' + remainingSeconds.toString().padStart(2, '0');
  }  

  togglePlayPause(playPauseButton) {
    const audio = playPauseButton.audio;
    const recordingItem = playPauseButton.closest(".recording-item");
  
    if (!audio) return;
  
    if (audio.paused) {
      audio.play();
      playPauseButton.textContent = "Pause";
      recordingItem.classList.add("bg-brand-blue"); // Add class to change background color when playing
    } else {
      audio.pause();
      playPauseButton.textContent = "Play";
      recordingItem.classList.remove("bg-brand-blue"); // Remove class when pausing
    }
  
    // Handle audio ended event
    audio.addEventListener("ended", () => {
      playPauseButton.textContent = "Play";
      recordingItem.classList.remove("bg-brand-blue"); // Remove class when the audio ends
    });
  }

  saveRecordingToLocalStorage(audioBlob) {
    const reader = new FileReader()
    reader.readAsDataURL(audioBlob)
    reader.onloadend = () => {
      const base64data = reader.result
      const recordings = JSON.parse(localStorage.getItem("recordings") || "[]")
      recordings.push({audio: base64data, status: "notUploaded"})
      localStorage.setItem("recordings", JSON.stringify(recordings))
      this.loadRecordingsFromLocalStorage()
    }
  }

  loadRecordingsFromLocalStorage() {
    const recordings = JSON.parse(localStorage.getItem("recordings") || "[]")
    this.recordingsListTarget.innerHTML = ""
    const template = document.getElementById("recordingTemplate")
  
    recordings.forEach((recording, index) => {
      const listItem = template.content.cloneNode(true)
      listItem.querySelector(".recording-index").textContent = index + 1
  
      const playPauseButton = listItem.querySelector(".play-pause-button"); // Move this line inside the loop
      playPauseButton.dataset.index = index;
      playPauseButton.addEventListener("click", this.playRecording.bind(this));
  
      const deleteButton = listItem.querySelector(".delete-button")
      deleteButton.dataset.index = index
      deleteButton.addEventListener("click", this.deleteRecording.bind(this))
  
      const uploadButton = listItem.querySelector(".upload-button")
      uploadButton.dataset.index = index
      uploadButton.textContent = recording.status === "uploaded" ? "Uploaded ✓" : "Upload"
      uploadButton.disabled = recording.status === "uploaded"
      uploadButton.addEventListener("click", this.uploadRecording.bind(this))
  
      const checkbox = listItem.querySelector(".upload-checkbox")
      checkbox.dataset.index = index
      uploadButton.disabled = recording.status === "uploaded"
  
      this.recordingsListTarget.appendChild(listItem)
    })
  }  
  
  async uploadRecording(event) {

    const index = event.currentTarget.dataset.index
    const bulk = event.currentTarget.dataset.bulk
    const recordings = JSON.parse(localStorage.getItem("recordings") || "[]")
    const status = recordings[index].status
    if(status === "uploaded"){
      return;
    }

    if(!bulk){
      this.showOverlay();
    }

    const audioBlob = this.getRecordingFromLocalStorage(index)
    const formData = new FormData()
  
    console.log("uploading: " + index);
    formData.append("authenticity_token", document.querySelector('meta[name="csrf-token"]').content)
    formData.append("transcription[audio_file]", audioBlob, `recording-${index}.wav`)
  
    try {
      const response = await fetch("/transcriptions", {
        method: "POST",
        body: formData,
      })
  
      if (response.ok) {
        console.log("Recording uploaded successfully")
        let recordings = JSON.parse(localStorage.getItem("recordings") || "[]")
        if (recordings[index]) {
          recordings[index].status = "uploaded"
          localStorage.setItem("recordings", JSON.stringify(recordings))
          this.loadRecordingsFromLocalStorage()
        }
      } else {
        console.error("Failed to upload recording")
        // Handle failure, e.g., display an error message
      }
    } catch (error) {
      console.error("Error:", error)
      // Handle error, e.g., display an error message
    }
    if(!bulk){
      this.hideOverlay();
    }
  }
  
  handleCheckboxChange() {
    const checkboxes = this.recordingsListTarget.querySelectorAll(".upload-checkbox");
    const bulkUploadButton = document.getElementById("uploadAllCheckedRecordingsButton");
    const bulkDeleteButton = document.getElementById("deleteAllCheckedRecordingsButton");
    const selectAllButton = document.getElementById("selectAllRecordingsButton");
    
    if (Array.from(checkboxes).some(checkbox => checkbox.checked)) {
      bulkUploadButton.style.display = "block";
      bulkDeleteButton.style.display = "block";
    } else {
      bulkUploadButton.style.display = "none";
      bulkDeleteButton.style.display = "none";
    }
  
    // Show or hide the "Select All" button based on the number of recordings
    if (checkboxes.length >= 2) {
      selectAllButton.style.display = "block";
    } else {
      selectAllButton.style.display = "none";
    }
  }
  
  async bulkUpload() {
    this.showOverlay();
    const checkboxes = this.recordingsListTarget.querySelectorAll(".upload-checkbox");
    for (const checkbox of checkboxes) {
      if (checkbox.checked) {
        const index = checkbox.dataset.index;
        // Call uploadRecording function instead of upload
        await this.uploadRecording({currentTarget: {dataset: {index: index, bulk: true}}});
        checkbox.checked = false;  // Uncheck the checkbox after upload
      }
    }
    this.handleCheckboxChange();
    this.loadRecordingsFromLocalStorage(); // refresh the list after bulk upload
    this.hideOverlay();
  }

  async bulkDelete() {
    const checkboxes = this.recordingsListTarget.querySelectorAll(".upload-checkbox");
    const indexesToDelete = [];
  
    for (const checkbox of checkboxes) {
      if (checkbox.checked) {
        indexesToDelete.push(checkbox.dataset.index);
      }
    }
  
    // Reverse sort the indexes to delete so that we don't modify the original indexes when splicing
    indexesToDelete.sort((a, b) => b - a);
  
    for (const index of indexesToDelete) {
      this.deleteRecording({currentTarget: {dataset: {index: index}}});
    }
    
    this.handleCheckboxChange();
    this.loadRecordingsFromLocalStorage(); // Refresh the list after bulk delete
  }
  
  deleteRecording(event) {
    const index = event.currentTarget.dataset.index
    const recordings = JSON.parse(localStorage.getItem("recordings") || "[]")
    recordings.splice(index, 1)
    localStorage.setItem("recordings", JSON.stringify(recordings))
    this.loadRecordingsFromLocalStorage()
  }
  

  getRecordingFromLocalStorage(index) {
    const recordings = JSON.parse(localStorage.getItem("recordings") || "[]")
    const base64data = recordings[index].audio
    if (base64data) {
      const byteCharacters = atob(base64data.split(",")[1])
      const byteNumbers = new Array(byteCharacters.length)
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
      }
      const byteArray = new Uint8Array(byteNumbers)
      return new Blob([byteArray], { type: "audio/wav" })
    }
    return null
  }

  showOverlay() {
    document.getElementById('upload_overlay').style.display = 'block';
  }
  
  hideOverlay() {
    document.getElementById('upload_overlay').style.display = 'none';
  }
  
}
