import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [  ]
    element = null;
    subject = null;
    connect() {
        this.element = document.querySelector('trix-editor');
    }

    insertMergeField (event){
        //Insert merge field wherever the caret is
        this.element.editor.insertString(event.params.field);

        //This puts the focus on the editor,
        // so the user can click a merge field and then keep typing
        this.element.editor.focus();
    }
}
